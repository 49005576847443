import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Video } from '../../components/video/Video';
import { TitulosDinamicos } from '../../components/TitulosDinamicos';
import { Preload } from '../../components/Preload';
import { ManualesLateral } from '../../components/ManualesLateral';
import { getManualesManualesVideo } from '../../actions/appActions';

export const Genericos = () => {

    const dispatch = useDispatch()

    const {loading} = useSelector( state => state.ui )

    const [datagenericos, setDataGenericos] = useState([])

    useEffect(() => {
        
        async function getBossanovas(){
            
            const url = '/v1/generico';
            const resultado = await dispatch( getManualesManualesVideo(url) )
            setDataGenericos(resultado)
            
        }
        getBossanovas()
    }, [])


    if (loading) {
        return (
            <Preload/>
        )
    }


  return (
    <>
        <div className="row own-container m-t-2">
            <div className="col-md-8 col-sm-12">
                <Video   data={datagenericos} />
            </div>
            <div className="col-md-4 col-sm-12">
                <TitulosDinamicos 
                    title={"Manuales Pance122"}
                />
                <ManualesLateral data={datagenericos}/>
            </div>
        </div>    
    </>
  )
}
