import { types } from "../types/types";


const initialStateVideoManual = {
     tittle_video: 'Selecione un video del menu lateral',
     url_video: '',
     folder_video: '',
}


export const aplicationReducer = ( state = initialStateVideoManual, action ) => {
    
      switch (action.type) {
          case types.setDataVideo:
               return {
                 ...state,
                 tittle_video: action.payload.tittle_video,
                 url_video: action.payload.url_video,
                 folder_video: action.payload.folder_video,
               }
          default:
              return state;
      }
}
