import React from 'react';

export const NavbarLogin = () => {
    return (
        <nav className="navbar navbar-expand-lg py-4 navbar-dark color-base-1">
            <div className="container-fluid">
                <a className="navbar-brand" href="/" ><img src="qi_blanco.png" alt="" width="60" height="48"/>
                    &nbsp;&nbsp;
                    MANUALES
                </a>
            </div>
        </nav>
    )
}
