import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createManual, createVideoInicial, getHoteles } from '../../actions/appActions';
import { finishLoading } from '../../actions/uiAction';
import { Alerts } from '../../components/messages/Alerts';
import { Preload } from '../../components/Preload';
import {  isExtensionVideoValid } from '../../helpers/Helpers';
import { useForm } from '../../hooks/useForm';


export const SubirVideoInicial = () => {
  

    const dispatch = useDispatch()

  const [filevideo, setFileVideo] = useState(null);


  useEffect( async() => {
     await dispatch( finishLoading() )
  }, []);
  


  const { loading } = useSelector( state => state.ui )

  const [ formState, handleInputName, reset ] = useForm({
    title_video: '',
  });

  const { title_video } = formState;



  const crearVideoInicial = async(event) => {
    
      event.preventDefault();
      

        const videovalido = isExtensionVideoValid(filevideo);
        (!videovalido) &&
        Alerts({ 
          icon: 'error',
          title: 'Oops...',
          text: 'El tipo de archivo para el video no es permitido debe ser [.mp4, .mp3, .avi]!',
          footer: '<a href="#">Qihotels manuales</a>'
        });
        if(!videovalido) return;

      const result = await dispatch( createVideoInicial ( {   title_video, filevideo } ) );
      
      if(result.ok){
         limpiarForm()
      }
      
      (result.ok) ?  Alerts({
          icon: 'success',
          title: 'Manual creado',
          text:  result.msg,
          footer: '<a href="">Why do I have this issue?</a>'
        }) 
        : 
        Alerts({ 
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        })

        
        
  }

  const  limpiarForm = () => {
       setFileVideo(null)
       reset()
       
  }



  const setVideoFile = (event) => {
    setFileVideo(event.target.files[0])
  }

  
  
  if (loading) {
    return (
        <Preload/>
    )
  }

  return (
         <>
            <div className="row own-container m-t-5">
                <div className="col-md-12">
                    <form className="row g-3 bg-crear-manual-form" onSubmit={crearVideoInicial}  autoComplete="off">
                          <h4>Crear video inicial</h4>
                            <div className='col-md-12'>
                              
                            

                              <div className='row col-12 m-t-10p'>
                                <div className="col-6">
                                    <strong className='text-up-input'>Escriba titulo para el video</strong>
                                    <input type="text" onChange={handleInputName} name="title_video" className="form-control" placeholder="Titulo video" required />
                                </div>
                                <div className="col-6">
                                    <strong className='text-up-input'>Subir manual [.mp4, .avi, .mp3]</strong>
                                    <input className="form-control" type="file" name="video_file" id="videoFile" onChange={setVideoFile} required/>
                                  </div>
                              </div>
                              

                            
                            <div className="col-12 m-t-15p txt-center">
                                <button type="submit"  className="btn btn-dark ancho-90 m-t-10p">Crear Video Inicial</button>
                            </div>
                          </div>
                    </form>
                </div>
            </div>
        </>
    
  )
}




