import React from 'react'

export const FilterComponent = ({ filterText, onFilter, onClear }) => {
    return (
        <>
            <input
                autoComplete='off'
                className="text-field"
                id="search"
                type="text"
                placeholder="Buscar por nombre"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <button type="button" className="clear-button btn-primary" onClick={onClear}>
                X
            </button>
	    </>
    )
}
