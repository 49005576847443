import requestApi from "../api/requestsApi";
import { types } from "../types/types";

export const getIndexTable = () => {
    try {
        
        return async( dispatch ) => {
            
            const { data } = await requestApi.get('/v1/index/table');
            return data;
        }
    } catch (error) {
        console.log('getIndexTable ', error);
    }
}



export const activeVideoSoftware = (id) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.patch('/v1/index/id/'+id);
            return data;
        }

    } catch (error) {
        console.log('activeVideoSoftware ', error);
    }
}


export const desactiveVideoIndex = (id) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.delete('/v1/index/id/'+id);
            return data;
        }

    } catch (error) {
        console.log('desactiveVideoIndex ', error);
    }
}


export const showIndexVideo = (id) => {
     
    try {
        
        return async( dispatch ) => { 
            const { data } = await requestApi.get('/v1/index/'+id);
            return data;
        }
      

    } catch (error) {
        console.log('showIndexVideo ', error);
    }
}


export const putEditarVideoIndex = ({ id,  title_video, setvideoindex }) => {
     
    
    return async( dispatch ) => { 

      try {

       let formData = new FormData();
       
       formData.append('title_video', title_video);
       formData.append('src_video', setvideoindex);

        const { data } = await requestApi.post('/v1/index/'+id, formData );
        return data;

      } catch (error) {
          console.log('putEditSoftware ', error);
      }
  }
    

  
}