import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setTitleVideo } from '../actions/appActions'
import { url_base } from '../env/env'

export const ManualesLateral = ({data}) => {

    

    const dispatch = useDispatch();
   
     
    useEffect(() => {
         const title_video = 'Selecione video del menu lateral';
         const src_video   = '';
         const folder_video   = '';
         
         const data_video = {
            title_video,
            src_video,
            folder_video
         }
         dispatch( setTitleVideo(data_video) )
    }, [])
    
   
        
    const showVideo = (event) => {

        event.preventDefault();
         const title_video = event.target.getAttribute('attr-title-video');
         const src_video   = event.target.getAttribute('attr-src-video');
         const folder_video   = event.target.getAttribute('attr-folder-video');
         
         const data_video = {
            title_video,
            src_video,
            folder_video
         }
         dispatch( setTitleVideo(data_video) )
        
    }
    
    return (
        <>
            
        <div className="accordion" id="accordionExample">

    
            { 
                (data.length !== 0)
                ?
                data.map( (item) => 
                    <div className="accordion-item" key={item.id.toString()}>
                        <h2 className="accordion-header" id={"headingThree"+item.id.toString()}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#acordionid"+item.id.toString()} aria-expanded="false" aria-controls={"acordionid"+item.id.toString()}>
                            {item.title_document} #{item.id.toString()}
                        </button>
                        </h2>
                        <div id={"acordionid"+item.id.toString()} className="accordion-collapse collapse" aria-labelledby={"headingThree"+item.id.toString()} data-bs-parent="#accordionExample">
                        <div className="accordion-body elementos-manual-video">
                            <strong><a href='/' rel="noreferrer"  onClick={ showVideo } className='txt-instruciones-acordion' attr-title-video={item.title_video} attr-src-video={item.src_video}  attr-folder-video={item.folder_video}>Ver video</a></strong>
                            <strong><a download rel="noreferrer" target="_blank" href={url_base()+'/'+item.folder_document+'/'+item.src_document} className='txt-instruciones-acordion'>Descargar Manual</a></strong>
                        </div>
                        </div>
                    </div>
                )
                :
                <ul> No hay contenido para mostrar :( </ul>
                
            }
            
        </div>
    </>
    )
}
