import React from 'react';

export const Preload = () => {
    return (
        <>
            <div className="custom-preload"> 
                <div className="txt-custom-preload">
                    <p>Un momento por favor...</p>
                    <div className="spinner"></div> 
                </div>
            </div> 
        </>
    )
}





