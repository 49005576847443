import { types } from "../types/types";


const initialStateAuth = {
    token: null,
    logged: false,
    status: '',
    usuario: {
        nombre: '',
        email: ''
    },
    rol: 'standar'
}


export const authReducer = ( state = initialStateAuth, action ) => {
      
      switch ( action.type ) {
          
            case types.login:
                return {
                  ...state,
                 token: action.payload.token,
                 status: action.payload.status,
                 user: action.payload.user,
                 logged: action.payload.logged,
                 rol: action.payload.rol
               }
            case types.logout:
                return {
                ...state,
                 token: action.payload.token,
                 status: action.payload.status,
                 user: action.payload.user,
                 logged: action.payload.logged,
                 rol: action.payload.rol
                }
              
          default:
              return state;
      }
}
