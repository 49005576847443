import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { activeSoftware, desactiveSoftware, getSoftwares,  putEditSoftware, showEditSoftware } from '../../actions/appActions';
import { FilterComponent } from '../../components/datatables/FilterComponent';
import { Alerts } from '../../components/messages/Alerts';
import { isExtensionFileValid, isExtensionSoftwareValid } from '../../helpers/Helpers';
import { useForm } from '../../hooks/useForm';

export const EditarSoftware = () => {
    const dispatch = useDispatch();

    const [loadingdt, setLoadinGdt] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [id, setId] = useState(0);
    const [updating, setUpdating] = useState(false);
    const [filedocumentsoftware, setFileDocumentSoftware] = useState(null);
    const [filedocumentmanual, setFileDocumentManual] = useState(null);
    const [ formState, handleInputName, reset, setValues ] = useForm({
        title_manual: '',
        title_software: '',
    });

    const { title_manual,  title_software } = formState;

    useEffect(() => {
        getData();
    }, []);
    
    
    
    const getData = async() => {
        
        const arr = [];
        const result = await dispatch(  getSoftwares() );
        
       
        (!result.ok) && Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Qihotesl manuales</a>'
        });
    
        if(!result.ok){return}
           
       console.log('kekek ',result.data)
        result.data.forEach( element => {
            arr.push({
                id: element.id,
                title_manual: element.title_manual,
                title_software:element.title_software,
                status: element.status
            });
        });
        setData(arr);
        setLoadinGdt(false);
    }
    
    
    
    const openCustomModal = async(id) => {
        const result = await dispatch( showEditSoftware(id) );
        (!result.ok) && Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Qihotesl manuales</a>'
        });
        if(!result.ok){
            setTimeout(() => {
                document.getElementById('cerrarmodal').click();
            }, 800);
            return
        }

        setId(result.data.id);
        document.getElementById('title_software').value = result.data.title_software;
        document.getElementById('title_manual').value = result.data.title_manual;
        
    }
    
    
    
    const desactivarSoftware = async(id) => {
        setLoadinGdt(true);
          const result = await dispatch(  desactiveSoftware(id) );
    
          (!result.ok) && Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Qihotesl manuales</a>'
          });
          if(!result.ok){return}
    
        getData();
    }
    
    const activarSoftware = async(id) => {
        setLoadinGdt(true);
         const result = await dispatch(  activeSoftware(id) );
    
         (!result.ok) && Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Qihotesl manuales</a>'
          });
          if(!result.ok){return}
          getData()
    }
    
     const filteredItems = data.filter(
        item => item.title_software && item.title_software.toLowerCase().includes(filterText.toLowerCase()),
     );
    
     const columns = [
        {
            name: 'Nombre manual',
            selector: row => row.title_manual,
        },
        {
            name: 'Nombre software',
            selector: row => row.title_software,
        },
        {
            name: 'Editar',
            button: true,
            cell: row => (
                
                (row.status == 1) 
                        ?
                          <button  target="_blank" onClick={ () => openCustomModal(row.id) } rel="noopener noreferrer" className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">Editar</button>
                        :
                        ''
                
            ),
        },
        {
            name: 'Acciones',
            button: true,
            cell: row => (
                      (row.status == 1) 
                        ?
                        <button   onClick={ () => desactivarSoftware(row.id)}  rel="noopener noreferrer" className="btn btn-danger btn-sm">Desactivar</button>
                        :
                        <button   onClick={ () => activarSoftware(row.id)}     rel="noopener noreferrer" className="btn btn-warning btn-sm">Activar</button>           
            ),
        },
     ];
    
     
    
    
     const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
    
        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);




    const editarManual = async(event) =>{
         
        event.preventDefault();

        if (filedocumentmanual) {
            const filevalido = isExtensionFileValid(filedocumentmanual);

           (!filevalido) &&
            Alerts({ 
                icon: 'error',
                title: 'Oops...',
                text: 'El tipo de archivo para el manual no es permitido debe ser [.docx, .pdf]!',
                footer: '<a href="#">Qihotels manuales</a>'
            });
            if(!filevalido) return;
        }


        
        if (filedocumentsoftware) {

            const softwarevalido = isExtensionSoftwareValid(filedocumentsoftware);

            (!softwarevalido) &&
            Alerts({ 
                icon: 'error',
                title: 'Oops...',
                text: 'El tipo de archivo para el software no es permitido debe ser [.exe]!',
                footer: '<a href="#">Qihotels manuales</a>'
            });
            if(!softwarevalido) return;
        }
        
       setUpdating(true);  
         const result = await dispatch( putEditSoftware({ id, title_software,  title_manual, filedocumentsoftware, filedocumentmanual }) );
       setUpdating(false); 
        console.log('result ', result);
       (!result.ok) && Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Qihotesl manuales</a>'
        });
        if(!result.ok){return}

        (result.ok) && Alerts({ 
            icon: 'success',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Qihotesl manuales</a>'
        });

        getData();
    }

    const setDocumentFileSoftware = (event) => {
        setFileDocumentSoftware(event.target.files[0]);
    }


    const setDocumentFileManual = (event) => {
        setFileDocumentManual(event.target.files[0]);
    }
    
    
      return (
        <>
          <div className='container m-t-15p'>
            <DataTable
                title="Gestion software"
                columns={columns}
                data={filteredItems}
                progressPending={loadingdt}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead/>
          </div>


          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Editar usuario</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={editarManual} autoComplete="off">
                            <div className="mb-3">
                                <label htmlFor="fnombre" className="form-label">Nombre software</label>
                                <input type="text"  onChange={handleInputName} className="form-control" name="title_software" id="title_software" aria-describedby="Name input title software to edit"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="femail" className="form-label">Nombre manual</label>
                                <input type="text" onChange={handleInputName} className="form-control" name="title_manual" id="title_manual" aria-describedby="Name input title manual to edit"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="femail" className="form-label">Cambiar manual manual</label>
                                <input className="form-control" type="file" name="document_manual" id="document_manual" onChange={setDocumentFileManual}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="femail" className="form-label">Cambiar software</label>
                                <input className="form-control" type="file" name="document_software" id="document_software" onChange={setDocumentFileSoftware}/>
                            </div>
                            <div className="modal-footer">
                                {
                                (updating) 
                                ?
                                <h4>Un momento por favor...</h4>
                                :
                                <>
                                <button type="button" id='cerrarmodal' className="btn btn-dark" data-bs-dismiss="modal">Cerrar</button>
                                <button type="submit" className="btn btn-success">Editar usuario</button> 
                                </>
                                }
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
      )
}





