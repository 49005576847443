import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createManual, createUsuario, getHoteles } from '../../actions/appActions';
import { Alerts } from '../../components/messages/Alerts';
import { Preload } from '../../components/Preload';
import {  isValidEmail } from '../../helpers/Helpers';
import { useForm } from '../../hooks/useForm';

export const CrearUsuario = () => {



    const dispatch = useDispatch()

  
    const [rol, setRol] = useState('');
    const [selecthoteles, setSelectHoteles] = useState([]);
  
  
    useEffect(() => {
        mostrarProductos();
    }, []);
  
  
    const mostrarProductos = async() => {
        const res = await dispatch( getHoteles() );
        setSelectHoteles(res.data);
    }
    
  
  
    const { loading } = useSelector( state => state.ui )
  
    const [ formState, handleInputName, reset ] = useForm({
      name_user: '',
      email_user: '',
      password: '',
    });
  
    const { name_user,  email_user,  password } = formState;
  
  
  
    const crearUsuario = async(event) => {
      
        event.preventDefault();
        
         const emailvalid = isValidEmail(email_user);
  
         (!emailvalid) &&
          Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: 'El tipo de email no es valido!',
            footer: '<a href="#">Qihotels manuales</a>'
          });
          if(!emailvalid) return;
  
           
        const result = await dispatch( createUsuario( { name_user,  email_user,  password,  rol } ) );
        
        if(result.ok){
           limpiarForm()
        }
        
        (result.ok) ?  Alerts({
            icon: 'success',
            title: 'Usuario creado',
            text:  result.msg,
            footer: '<a href="">Why do I have this issue?</a>'
          }) 
          : 
          Alerts({ 
            icon: 'error',
            title: 'Oops...',
            text: result.msg,
            footer: '<a href="">Why do I have this issue?</a>'
          })
  
          
          
    }
  
    const  limpiarForm = () => {
         reset()
    }
  
  
  
    const setSelectedOption = (value) => {
        setRol(value);
    }
    
    if (loading) {
      return (
          <Preload/>
      )
    }


  return (
    <>
        <div className="row own-container m-t-7">
            <div className="col-md-12">
                <form className="row g-3 bg-crear-manual-form" onSubmit={crearUsuario}  autoComplete="off">
                    <h4>Crear usuario</h4>
                        <div className='col-md-12'>
                        
                        <div className='row col-12'>
                            <div className="col-6">
                                <input type="text" onChange={handleInputName} name="name_user" className="form-control" placeholder="Nombre usuario" required />
                            </div>
                            <div className="col-6">
                                <input type="text" onChange={handleInputName} name="email_user" className="form-control" placeholder="Email usuario" required />
                            </div>
                        </div> 

                        <div className='row col-12 m-t-10p'>
                            <div className="col-6">
                                <input type="text" onChange={handleInputName} name="password" className="form-control" placeholder="Password" required />
                            </div>
                            <div className="col-6">
                                <select className="form-select" aria-label="Default select example" onChange={e => setSelectedOption(e.target.value)} required>
                                    <option value=''>Selecione rol</option>
                                    <option value='admin'>Administrador</option>
                                    <option value='standar'>Standar</option>
                                </select>
                            </div>
                        </div>
                                                

                        
                        <div className="col-12 m-t-15p txt-center">
                            <button type="submit"  className="btn btn-dark ancho-90 m-t-10p">Crear Usuario</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
