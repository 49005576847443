

export const isExtensionFileValid = (file) => {
    
       try {
           const extension =  getExtension(file);
           return ['pdf', 'docx'].includes(extension) ? true : false;
       } catch (error) {
           console.log('isExtensionFileValid ', error);
       }
}


export const isExtensionVideoValid = (file) => {
    try {
          const extension =  getExtension(file);
          return ['mp4', 'avi', 'mp3'].includes(extension) ? true : false;
    } catch (error) {
        console.log('isExtensionVideoValid ', error);
    }
}


export const isExtensionSoftwareValid = (file) => {
    try {
        const extension =  getExtension(file);
        return ['exe'].includes(extension) ? true : false;
    } catch (error) {
        console.log('isExtensionSoftwareValid ', error);
    }
}


const getExtension = (file) => {
    try {
        const cadena = file.name.split('.');
        const extension = cadena[cadena.length -1];
        return extension.toLowerCase();
    } catch (error) {
        console.log('getExtension ', error);
    }
}




export const isValidEmail = (email) => {
    try {
        
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        return (email.match(validRegex)) ? true : false;

    } catch (error) {
        console.log('isValidEmail ', error);
    }
}
