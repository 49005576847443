import requestApi from "../api/requestsApi";
import { types } from "../types/types";
import { startLogOut } from '../actions/authActions';
import { finishLoading, setError, startLoading } from "./uiAction";

export const getListaSoftware = () => {

    try {

        return async( dispatch ) => {

              dispatch( startLoading() )
            
              const { data } = await requestApi.get('/v1/softwares');

              
              if ( data.status === types.userNotFound) {
                  dispatch( startLogOut() )
              }

               dispatch( finishLoading() )
              return data;
        }
        
    } catch (error) {
        console.log('getListaSoftware ', error)
    }
}



export const getManualesManualesVideo = (url) => {

    try {

        return async( dispatch ) => {

              dispatch( startLoading() )
            
              const { data } = await requestApi.get(url);

               if ( data.status === types.userNotFound) {
                   dispatch( startLogOut() )
               }
              
                dispatch( finishLoading() );
                if (data.ok) {
                   return data.data;
                }else{
                    setError(data.msg);
                }

            
        }
        
    } catch (error) {
        console.log('getManualesManualesVideo ', error)
    }
}




export const getDataIndex = () => {
    try {
         
         return async( dispatch ) => {
                
            dispatch( startLoading() );

            const { data } = await requestApi.get('/v1/index');
              
            
             if (data.ok) {
                 return data.data;
             }else{
                 dispatch( await startLogOut() )
                 setError(data.msg);
             }

            dispatch( finishLoading() );

            
         }
         

    } catch (error) {
        console.log('getDataIndex ', error)
    }
}



export const setTitleVideo = (data) => {
    try {

         return ( dispatch ) => {
           dispatch( startLoading() );

              dispatch( tituloVideo(data) )
             
            dispatch( finishLoading() );
         }
        
    } catch (error) {
        console.log('setTitleVideo ',setTitleVideo)
    }
}



export const getHoteles = () => {
    
      return async( dispatch ) => {
            try {
            
                dispatch( startLoading() )
                    const  { data } = await requestApi.get('/v1/hoteles');
                dispatch( finishLoading() )
                  return data;
            } catch (error) {
                dispatch( finishLoading() )
                console.log('getHoteles ', getHoteles)
            }
      }
}


export const createManual = ( { title_document,  filedocument,  title_video, filevideo, select } ) => {

    return async( dispatch ) => {
       
           try {

                dispatch( startLoading() )

                    let formData = new FormData();
        
                    formData.append('title_document', title_document);
                    formData.append('filedocument', filedocument);
                    formData.append('title_video', title_video);
                    formData.append('filevideo', filevideo);
                    formData.append('hotel', select.trim());
        
                    const  { data } = await requestApi.post('/v1/'+select.trim(), formData);
                dispatch( finishLoading() )
                    return data;
               
           } catch (error) {
                dispatch( finishLoading() )
           }
    }
}


export const createUsuario = ( { name_user,  email_user,  password,  rol } ) => {
    try {
        
        return async (dispatch) => {

            const { data } = await requestApi.post('/v1/usuarios', { name_user,  email_user,  password,  rol });
            
            return data;

        }

    } catch (error) {
        console.log('createUsuario '. error);
    }
}



export const getUsers = () => {
    try {
        
        return async( dispatch ) => {

            const { data } = await requestApi.get('/v1/usuarios');
            return data;

        }
        
    } catch (error) {
        console.log('getUsers ', error);
    }
}


export const desactiveUser = (id) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.delete('/v1/usuarios/id/'+id);
            console.log('desactiveUser ok ',data)
            return data;
        }
        
    } catch (error) {
        console.log('desactiveUser ', error);
    }
}

export const activeUser = (id) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.patch('/v1/usuarios/id/'+id);
            console.log('desactiveUser false',data)
            return data;
        }
        
    } catch (error) {
        console.log('activeUser ', error);
    }
}

export const editUser = () => {
    try {
        
        const { data } = requestApi.put('/v1/usuarios');
        return data;
        
    } catch (error) {
        console.log('editUser ', error);
    }
}

export const createSoftware = ( { name_software,  filesoftware, name_manual, filedocument } ) => {

    return async( dispatch ) => {
       
           try {

                dispatch( startLoading() )

                    let formData = new FormData();
        
                    formData.append('name_software', name_software);
                    formData.append('filesoftware', filesoftware);
                    formData.append('name_manual', name_manual);
                    formData.append('filedocument', filedocument);

        
                    const  { data } = await requestApi.post('/v1/softwares', formData);
                    
                dispatch( finishLoading() )
                    return data;
               
           } catch (error) {
                dispatch( finishLoading() )
           }
    }
}


const tituloVideo = (data) => {
    return {
        type: types.setDataVideo,
        payload: {
           tittle_video: data.title_video,
           url_video: data.src_video,
           folder_video: data.folder_video,
        }
    }
}





export const getManuales = () => {
    try {
        

         return async( dispatch ) => {
             
             const { data } = await requestApi.get('/v1/gestionmanuales');
             console.log('viooo ', data)
             return data;
         }
    } catch (error) {
        console.log('getManuales ', error)
    }
}


export const desactiveManual = (id, hotel_manuales) => {
    try {

        return async( dispatch ) => {
            const { data } = await requestApi.delete('/v1/gestionmanuales/id/'+id+'/hotel_manuales/'+hotel_manuales);
            console.log('desactiveManual ok ',data)
            return data;
        }
        
    } catch (error) {
        console.log('desactiveManual ', error);
    }
}


export const activeManual = (id, hotel_manuales) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.patch('/v1/gestionmanuales/id/'+id+'/hotel_manuales/'+hotel_manuales);
            console.log('desactiveUser ok ',data)
            return data;
        }

    } catch (error) {
        console.log('activeManual ', error);
    }
}



export const getSoftwares = () => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.get('/v1/gestionsoftware');
            return data;
        }

    } catch (error) {
        console.log('activeManual ', error);
    }
}


export const desactiveSoftware = (id) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.delete('/v1/gestionsoftware/id/'+id);
            console.log('desactiveSoftware ok ',data)
            return data;
        }

    } catch (error) {
        console.log('activeManual ', error);
    }
}


export const activeSoftware = (id) => {
    try {
        
        return async( dispatch ) => {
            const { data } = await requestApi.patch('/v1/gestionsoftware/id/'+id);
            console.log('desactiveSoftware ok ',data)
            return data;
        }

    } catch (error) {
        console.log('activeManual ', error);
    }
}



export const showEditUser = (id) => {
     
    try {
        
        return async( dispatch ) => { 
            const { data } = await requestApi.get('/v1/usuarios/'+id);
            return data;
        }
      

    } catch (error) {
        console.log('showEditUser ', error);
    }
}


export const putEditUser = ({ id, name_user,  email_user,  password, rol, changepassword }) => {
     
      try {
          
        return async( dispatch ) => { 
            const { data } = await requestApi.put('/v1/usuarios/'+id,{ id, name:name_user,  email:email_user,  password, rol });
            return data;
        }
        

      } catch (error) {
          console.log('putEditUser ', error);
      }
}




export const showEditSoftware = (id) => {
     
    try {
        
        return async( dispatch ) => { 
            const { data } = await requestApi.get('/v1/gestionmanuales/'+id);
            return data;
        }
      

    } catch (error) {
        console.log('showEditSoftware ', error);
    }
}



export const putEditSoftware = ({ id, title_software,  title_manual, filedocumentsoftware, filedocumentmanual }) => {
     
    
      return async( dispatch ) => { 

        try {

         let formData = new FormData();
         
         formData.append('title_software', title_software);
         formData.append('title_manual', title_manual);
         formData.append('src_software', filedocumentsoftware);
         formData.append('src_manual', filedocumentmanual);

          const { data } = await requestApi.post('/v1/gestionsoftware/'+id, formData );
          return data;

        } catch (error) {
            console.log('putEditSoftware ', error);
        }
    }
      

    
}



export const createVideoInicial = ( {  title_video, filevideo } ) => {
    try {

        return async ( dispatch ) => {
            dispatch( startLoading() )

            let formData = new FormData();

            formData.append('title_video', title_video);
            formData.append('filevideo', filevideo);

            const  { data } = await requestApi.post('/v1/index', formData);
        dispatch( finishLoading() )
            return data;
        }
        
    } catch (error) {
        
    }
}