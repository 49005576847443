import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { activeUser, desactiveUser, getUsers, putEditUser, showEditUser } from '../../actions/appActions';
import { FilterComponent } from '../../components/datatables/FilterComponent';
import { Alerts } from '../../components/messages/Alerts';
import { useForm } from '../../hooks/useForm';


export const EditarUsuarios = () => {
   const dispatch = useDispatch();
   const [loadingdt, setLoadinGdt] = useState(true);
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   const [filterText, setFilterText] = useState('');
   const [data, setData] = useState([]);
   const [changepassword, setChangePassword] = useState(true);
   const [id, setId] = useState(0);
   const [rol, setRol] = useState('');
   const [updating, setUpdating] = useState(false);

   const [ formState, handleInputName, reset, setValues ] = useForm({
      name_user: '',
      email_user: '',
      password: ''
   });

   const { name_user,  email_user,  password } = formState;
   useEffect(() => {
      getData();
   }, []);


   const getData = async() => {
   const arr = [];
   const result = await dispatch(  getUsers() );
   
      (!result.ok) && Alerts({ 
         icon: 'error',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotesl manuales</a>'
         });
      if(!result.ok){return}

      result.data.forEach( element => {
         arr.push({
         id: element.id,
         name: element.name,
         email:element.email,
         rol: element.rol,
         status: element.status
         });
      });

      
      setData(arr);
      setLoadinGdt(false);
   }


   const openCustomModal = async(id) => {
      const result = await dispatch( showEditUser(id) );
      (!result.ok) && Alerts({ 
         icon: 'error',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotesl manuales</a>'
      });
      if(!result.ok){return}

      setId(result.data.id);
      document.getElementById('fnombre').value = result.data.name;
      document.getElementById('femail').value = result.data.email;
      document.getElementById('fselect').value = result.data.rol;
   }

   const desactivarUsuario = async(id) => {

      setLoadinGdt(true);

      const result = await dispatch(  desactiveUser(id) );
      (!result.ok) && Alerts({ 
         icon: 'error',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotesl manuales</a>'
      });
      if(!result.ok){return}

      getData();
   }

   
   const activarUsuario = async(id) => {

      setLoadinGdt(true);

      const result = await dispatch(  activeUser(id) );
      (!result.ok) && Alerts({ 
         icon: 'error',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotesl manuales</a>'
      });
      
      if(!result.ok){return}

      getData();
   }


   const filteredItems = data.filter(
      item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
   );


   const columns = [
      {
         name: 'Nombre',
         selector: row => row.name,
      },
      {
         name: 'Email',
         selector: row => row.email,
      },
      {
         name: 'Rol',
         selector: row => row.rol,
      },
      {
         name: 'Editar',
         button: true,
         cell: row => (
            (row.status == 1) 
            ?
               <button  target="_blank" onClick={ () => openCustomModal(row.id) } rel="noopener noreferrer" className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">Editar</button>
            :
            ''
         ),
      },
      {
         name: 'Acciones',
         button: true,
         cell: row => (
            (row.status == 1) 
            ?
            <button   onClick={ () => desactivarUsuario(row.id)}  rel="noopener noreferrer" className="btn btn-danger btn-sm">Desactivar</button>
            :
            <button   onClick={ () => activarUsuario(row.id)}     rel="noopener noreferrer" className="btn btn-warning btn-sm">Activar</button>           
            ),
      },
   ];


   const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
         if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
         }
      };
   return (
      <FilterComponent onFilter={e =>
      setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
   );
   }, [filterText, resetPaginationToggle]);


   const cambioPassword = () => {
      setChangePassword(!changepassword);
   }


   const editarUsuario = async(event) => {

      event.preventDefault();  

      setUpdating(true);  
       const result = await dispatch( putEditUser({ id, name_user,  email_user,  password, rol, changepassword }) );
      setUpdating(false);

      (!result.ok) && Alerts({ 
         icon: 'error',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotesl manuales</a>'
      });
      if(!result.ok){return}

      (result.ok) && Alerts({ 
         icon: 'success',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotesl manuales</a>'
      });

      getData();
   }


   const setSelectedOption = (value) => {
    setRol(value);
   }


return (
<>
<div className='container m-t-15p'>
   <DataTable
   title="Gestion usuarios"
   columns={columns}
   data={filteredItems}
   progressPending={loadingdt}
   pagination
   paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
   subHeader
   subHeaderComponent={subHeaderComponentMemo}
   selectableRows
   persistTableHead/>
</div>
<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Editar usuario</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <form onSubmit={editarUsuario} autoComplete="off">
               <div className="mb-3">
                  <label htmlFor="fnombre" className="form-label">Nombre</label>
                  <input type="text" onChange={handleInputName} className="form-control" name="name_user" id="fnombre" aria-describedby="emailHelp"/>
               </div>
               <div className="mb-3">
                  <label htmlFor="femail" className="form-label">Email</label>
                  <input type="email" onChange={handleInputName} className="form-control" name="email_user" id="femail" aria-describedby="emailHelp"/>
               </div>
               <div className="mb-3">
                  <label htmlFor="fselect" className="form-label">Rol</label>
                  <select id="fselect" className="form-select" onChange={e =>
                     setSelectedOption(e.target.value)}>
                     <option value="">Selecione un rol</option>
                     <option value="admin">Administrador</option>
                     <option value="standar">Standar</option>
                  </select>
               </div>
               <div className="mb-3">
                  <label htmlFor="fpassword" className="form-label">Cambiar password</label>
                  <input type="text" onChange={handleInputName} className="form-control" name="password" id="fpassword" disabled={changepassword}/>
               </div>
               <div className="mb-3 form-check">
                  <input type="checkbox" className="form-check-input" id="fcheckbox" onClick={cambioPassword}/>
                  <label className="form-check-label" htmlFor="fcheckbox">Cambiar password</label>
               </div>
               <div className="modal-footer">
                  {
                  (updating) 
                  ?
                  <h4>Un momento por favor...</h4>
                  :
                  <>
                  <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Cerrar</button>
                  <button type="submit" className="btn btn-success">Editar usuario</button> 
                  </>
                  }
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
</>
)
}