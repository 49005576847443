import React, {  useEffect, useState } from 'react';


import {
    BrowserRouter as Router,
    Switch
  } from "react-router-dom";

import { PrivateRoute } from './PrivateRoutes';
import { PublicRoute } from './PublicRoutes';

import { Login } from '../pages/login/Login';
import { DashboardRoutes } from './DashboardRoutes';
import { Preload } from '../components/Preload';
import { useCheckToken } from '../hooks/useCheckToken';
import { useSelector } from 'react-redux';



export const AppRouter = () => {



    const { logged } = useSelector(state => state.auth )

 

    const [checking, setChecking] = useState(false);
    const [isLogged,   setIsLogged] = useState(false)
    
    const [ initCheckToken ] = useCheckToken()

    
   
    useEffect(() => {

        const token = initCheckToken();

        if (!token.logged){
            setIsLogged(token.logged)
            setChecking(false)
        }

        setIsLogged(token.logged)
        setChecking(false)

        console.log('AppRouter')

    }, [logged, initCheckToken])


    if (checking) {
        return(
            <Preload/>
        )
    }
  
    return ( 
        
        
        <Router>
            <div>
                <Switch> 
                    <PublicRoute 
                        exact 
                        path="/login" 
                        component={ Login } 
                        isAuthenticated={ isLogged }
                    />
                    
                    <PrivateRoute 
                        path="/" 
                        component={ DashboardRoutes } 
                        isAuthenticated={ isLogged }
                    />
                </Switch>
            </div>
        </Router>
    )
}
