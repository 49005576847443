import Swal from 'sweetalert2';

export const Alerts = (message) => {
  
    Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.text,
        footer: '<span>Qihotels manuales</span>'
    })
}
