import axios from 'axios';
import { production } from '../env/env';
import { useCheckToken } from '../hooks/useCheckToken';

const baseURL = production() ? 'https://bkqimanuales.qihotels.net/api' : 'http://localhost:8080/api';

const requestApi = axios.create({ baseURL });

requestApi.interceptors.request.use(
    async(config) => {

        const [ initCheckToken ] = useCheckToken()
        
        
        const token = initCheckToken().token;

        if ( token !== '' || token !== null || token.logged !== false ) {
            config.headers['Authorization'] = 'Bearer '+token;
        }
       
        return config;
    }
);



export default requestApi;
