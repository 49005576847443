import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Video } from '../../components/video/Video';
import { TitulosDinamicos } from '../../components/TitulosDinamicos';
import { Preload } from '../../components/Preload';
import { ManualesLateral } from '../../components/ManualesLateral';
import { getManualesManualesVideo } from '../../actions/appActions';

export const Solyluna = () => {

    const dispatch = useDispatch()

    const {loading} = useSelector( state => state.ui )

    const [datasolyluna, setDataSolYLuna] = useState([])

   

    useEffect(() => {
        
        async function getBossanovas(){
            
            const url = '/v1/solyluna';
            const resultado = await dispatch( getManualesManualesVideo(url) )
            setDataSolYLuna(resultado)
            
        }
        getBossanovas()
    }, [])


    
    
     if (loading) {
         return (
             <Preload/>
         )
     }


    return (
        <>
            <div className="row own-container m-t-2">
                <div className="col-md-8 col-sm-12">
                   <Video   data={datasolyluna} />
                </div>
                <div className="col-md-4 col-sm-12">
                    <TitulosDinamicos 
                        title={"Manuales Sol y luna"}
                    />
                    <ManualesLateral data={datasolyluna}/>
                </div>
            </div>
        </>
    )
}

