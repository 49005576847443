import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Video } from '../../components/video/Video';
import { TitulosDinamicos } from '../../components/TitulosDinamicos';
import { Preload } from '../../components/Preload';
import { ManualesLateral } from '../../components/ManualesLateral';
import { getManualesManualesVideo, setTitleVideo } from '../../actions/appActions';

export const Pance122 = () => {

    const dispatch = useDispatch()

    const {loading} = useSelector( state => state.ui )

    const [datapance122, setDataPance122] = useState([])

   

    useEffect(() => {
        
        async function getPance122(){
            
            const url = '/v1/pance122';
            const resultado = await dispatch( getManualesManualesVideo(url) )
            setDataPance122(resultado)
        }
        getPance122()
    }, [])

    
    
     if (loading) {
         return (
             <Preload/>
         )
     }


    return (
        <>
            <div className="row own-container m-t-2">
                <div className="col-md-8 col-sm-12">
                   <Video   data={datapance122} />
                </div>
                <div className="col-md-4 col-sm-12">
                    <TitulosDinamicos 
                        title={"Manuales Pance122"}
                    />
                    <ManualesLateral data={datapance122}/>
                </div>
            </div>
        </>
    )
}
