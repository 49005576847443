import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getListaSoftware } from '../../actions/appActions';
import { ListaSoftware } from '../../components/ListaSoftware'
import { Preload } from '../../components/Preload';
import { TitulosDinamicos } from '../../components/TitulosDinamicos';

export const Software = () => {

    const dispatch = useDispatch()

    const {loading} = useSelector( state => state.ui )

    const [datasoftware, setDataSoftware] = useState([])

    

    useEffect(() => {
        
        async function getSoftwares(){
             
            
            const resultado = await dispatch( getListaSoftware() )

            if (resultado.ok) {
                setDataSoftware(resultado.data)
            }else{
                
            }
            
        }
        getSoftwares()
    }, [dispatch])
    
     if (loading) {
         return (
             <Preload/>
         )
     }
    
    return (
        <div>

            <div className="row own-container m-t-2">
                <div className="col-md-12 col-sm-12">
                    <TitulosDinamicos 
                       title={"Listado de software"}
                    />
                    <ListaSoftware data={datasoftware}/>
                </div>
                {/* <div className="col-md-3">
                    <TitulosDinamicos 
                       title={"Manuales"}
                    />
                    <TodoListItem />
                </div> */}
            </div>
            
          

        </div>
    )
}
