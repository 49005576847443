import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  createSoftware } from '../../actions/appActions';
import { finishLoading } from '../../actions/uiAction';
import { Alerts } from '../../components/messages/Alerts';
import { Preload } from '../../components/Preload';
import { isExtensionFileValid, isExtensionSoftwareValid } from '../../helpers/Helpers';
import { useForm } from '../../hooks/useForm';

export const Subirsoftware = () => {

  const dispatch = useDispatch()


  useEffect(() => {
    dispatch( finishLoading() )
  }, [])
  

  const [filesoftware, setFileSoftware] = useState(null);
  const [filedocument, setFileDocument] = useState(null);


  const { loading } = useSelector( state => state.ui )

  const [ formState, handleInputName, reset ] = useForm({
    name_software: '',
    name_manual: ''
  });

  const { name_software, name_manual } = formState;


  const setSoftwareFile = (event) => {
    setFileSoftware(event.target.files[0]);
  }

  const setDocumentFile = (event) => {
    setFileDocument(event.target.files[0])
  }


  const subirSoftware = async(event) => {

      event.preventDefault();


      const filevalido = isExtensionFileValid(filedocument);

       (!filevalido) &&
        Alerts({ 
          icon: 'error',
          title: 'Oops...',
          text: 'El tipo de archivo para el manual no es permitido debe ser [.docx, .pdf]!',
          footer: '<a href="#">Qihotels manuales</a>'
        });
        if(!filevalido) return;

        
      const softwarevalido = isExtensionSoftwareValid(filesoftware);

      (!softwarevalido) &&
      Alerts({ 
        icon: 'error',
        title: 'Oops...',
        text: 'El tipo de archivo para el software no es permitido debe ser [.exe]!',
        footer: '<a href="#">Qihotels manuales</a>'
      });
      if(!softwarevalido) return;

      const result = await dispatch( createSoftware( { name_software,  filesoftware, name_manual, filedocument } ))

      if(result.ok){
        limpiarForm()
      }
     
     (result.ok) ?  Alerts({
         icon: 'success',
         title: 'Software creado',
         text:  result.msg,
         footer: '<a href="">Qihotels manuales?</a>'
       }) 
       : 
       Alerts({ 
         icon: 'error',
         title: 'Oops...',
         text: result.msg,
         footer: '<a href="">Qihotels manuales?</a>'
       })

  }


  const  limpiarForm = () => {
    setFileSoftware(null)
    reset()
    
}


  if (loading) {
    return (
        <Preload/>
    )
  }


  return (
    <>
            <div className="row own-container m-t-7">
                <div className="col-md-12">
                   <form className="row g-3 bg-crear-manual-form" onSubmit={subirSoftware}  autoComplete="off">
                                <h4>Subir software</h4>
                                 <div className='col-md-12'>
                                   <div className='row col-12'>
                                      <div className="col-6">
                                          <strong className='text-up-input'>Escriba nombre para el software</strong>
                                         <input type="text" onChange={handleInputName} name="name_software" className="form-control" placeholder="Nombre software" required />
                                      </div>
                                      <div className="col-6">
                                         <strong className='text-up-input'>Subir software [.exe]</strong>
                                         <input className="form-control" type="file" name="document_file" id="documentFile" onChange={setSoftwareFile} required />
                                       </div>
                                   </div> 

                                   <div className='row col-12 m-t-10p'>
                                      <div className="col-6">
                                         <strong className='text-up-input'>Escriba nombre para el manual</strong>
                                         <input type="text" onChange={handleInputName} name="name_manual" className="form-control" placeholder="Nombre manual"  required />
                                      </div>
                                      <div className="col-6">
                                         <strong className='text-up-input'>Subir manual [.docx, .pdf]</strong>
                                         <input className="form-control" type="file" name="document_file" id="documentFile" onChange={setDocumentFile} required />
                                       </div>
                                   </div>

                                   
                                  <div className="col-12 m-t-15p txt-center">
                                      <button type="submit"  className="btn btn-dark ancho-90 m-t-10p">Subir Software</button>
                                  </div>
                                </div>
                    </form>
                </div>
            </div>
        </>
  )
}
