import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createManual, getHoteles } from '../../actions/appActions';
import { Alerts } from '../../components/messages/Alerts';
import { Preload } from '../../components/Preload';
import { isExtensionFileValid, isExtensionVideoValid } from '../../helpers/Helpers';
import { useForm } from '../../hooks/useForm';


export const Crearmanual = () => {
  

    const dispatch = useDispatch()

  
  const [select, setSelect] = useState('');
  const [filedocument, setFileDocument] = useState(null);
  const [filevideo, setFileVideo] = useState(null);
  const [selecthoteles, setSelectHoteles] = useState([]);


  useEffect(() => {
      mostrarProductos();
  }, []);


  const mostrarProductos = async() => {
      const res = await dispatch( getHoteles() );
      setSelectHoteles(res.data);
  }
  


  const { loading } = useSelector( state => state.ui )

  const [ formState, handleInputName, reset ] = useForm({
    title_document: '',
    title_video: '',
  });

  const { title_document,  title_video } = formState;



  const crearProducto = async(event) => {
    
      event.preventDefault();
      
       const filevalido = isExtensionFileValid(filedocument);

       (!filevalido) &&
        Alerts({ 
          icon: 'error',
          title: 'Oops...',
          text: 'El tipo de archivo para el manual no es permitido debe ser [.docx, .pdf]!',
          footer: '<a href="#">Qihotels manuales</a>'
        });
        if(!filevalido) return;

        const videovalido = isExtensionVideoValid(filevideo);
        (!videovalido) &&
        Alerts({ 
          icon: 'error',
          title: 'Oops...',
          text: 'El tipo de archivo para el video no es permitido debe ser [.mp4, .mp3, .avi]!',
          footer: '<a href="#">Qihotels manuales</a>'
        });
        if(!videovalido) return;

      const result = await dispatch( createManual( { title_document,  filedocument,  title_video, filevideo,  select } ) );
      
      if(result.ok){
         limpiarForm()
      }
      
      (result.ok) ?  Alerts({
          icon: 'success',
          title: 'Manual creado',
          text:  result.msg,
          footer: '<a href="">Why do I have this issue?</a>'
        }) 
        : 
        Alerts({ 
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        })

        
        
  }

  const  limpiarForm = () => {
       setFileDocument(null)
       setFileVideo(null)
       reset()
       
  }


  const setDocumentFile = (event) => {
    setFileDocument(event.target.files[0])
  }

  const setVideoFile = (event) => {
    setFileVideo(event.target.files[0])
  }

  const setSelectedOption = (value) => {
     setSelect(value);
  }
  
  if (loading) {
    return (
        <Preload/>
    )
  }

  return (
         <>
            <div className="row own-container m-t-5">
                <div className="col-md-12">
                    <form className="row g-3 bg-crear-manual-form" onSubmit={crearProducto}  autoComplete="off">
                          <h4>Crear manual</h4>
                            <div className='col-md-12'>
                              
                              <div className='row col-12'>
                                <div className="col-6">
                                    <strong className='text-up-input'>Escriba titulo para el manual</strong>
                                    <input type="text" onChange={handleInputName} name="title_document" className="form-control" placeholder="Titulo manual" required />
                                </div>
                                <div className="col-6">
                                    <strong className='text-up-input'>Subir manual [.docx, .pdf]</strong>
                                    <input className="form-control" type="file" name="document_file" id="documentFile" onChange={setDocumentFile} required/>
                                  </div>
                              </div> 

                              <div className='row col-12 m-t-10p'>
                                <div className="col-6">
                                    <strong className='text-up-input'>Escriba titulo para el video</strong>
                                    <input type="text" onChange={handleInputName} name="title_video" className="form-control" placeholder="Titulo video" required />
                                </div>
                                <div className="col-6">
                                    <strong className='text-up-input'>Subir manual [.mp4, .avi, .mp3]</strong>
                                    <input className="form-control" type="file" name="video_file" id="videoFile" onChange={setVideoFile} required/>
                                  </div>
                              </div>
                              

                              <div className='row col-12 m-t-10p'> 
                                  <div className="col-12">
                                      <strong className='text-up-input'>Selecion hotel al que pertenece el manual</strong>
                                      <select className="form-select" aria-label="Default select example" onChange={e => setSelectedOption(e.target.value)} required>
                                          <option value={''}>Selecione hotel</option>
                                          {
                                              selecthoteles.map( (item) => {
                                                    return (<option value={item.tbl_video} key={item.id}>{item.name_hotel}</option>)
                                              })
                                          }
                                      </select>
                                  </div>
                              </div>
                              

                            
                            <div className="col-12 m-t-15p txt-center">
                                <button type="submit"  className="btn btn-dark ancho-90 m-t-10p">Crear Manual</button>
                            </div>
                          </div>
                    </form>
                </div>
            </div>
        </>
    
  )
}


