import React from 'react';
// import { AppRouter } from './components/routes/AppRouter';
// import { AuthContext } from './components/auth/AuthContext';
// import { authReducer } from './components/auth/authReducer';
// import { AuthProvider } from './components/auth/AuthContext';
import { Provider } from 'react-redux';
import { AppRouter } from './routes/AppRouter';
import { store } from './store/store';


export const App = () => {

  return (
      <Provider store={store}> 
          <AppRouter/>
      </Provider>
  )
}

