

export const url_base = () => {
    return  production() ? 'https://bkqimanuales.qihotels.net' : 'http://localhost:8080';
}



export const globals = {
    adminCompareRol: 'admin',
    adminRol : 'lKVZOKd&Ev*@yeoVo1ZDPDoDeg@x#tXwd4cJxFB@ch6fyxltj!'
}

export const rolesValues = {  
    userStandar: 'oGZQ*Lze*vp^glQyMb8bul7tzMJ5*#V1tqK!EDWz',
    userAdmin:   'lKVZOKd&Ev*@yeoVo1ZDPDoDeg@x#tXwd4cJxFB@ch6fyxltj!'
}


export const production = () => {
    return true;
}

