import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Bossanova } from '../pages/bossanova/Bossanova';
import { Solyluna } from '../pages/solyluna/Solyluna';
import { Pance122 } from '../pages/pance122/Pance122';
import { Eclipse } from '../pages/eclipse/Eclipse';
import { Index } from '../pages/index/Index';
import { O2 } from '../pages/o2/O2';
import { Software } from '../pages/software/Software';
import { Navbar } from '../components/navbar/Navbar';
import { Crearmanual } from '../pages/crearmanual/Crearmanual';
import { Subirsoftware } from '../pages/subirsoftware/Subirsoftware';
import { Genericos } from '../pages/genericos/Genericos';
import { CrearUsuario } from '../pages/crearusuario/CrearUsuario';
import { EditarUsuarios } from '../pages/editarusuario/EditarUsuarios';
import { EditarManuales } from '../pages/editarmanuales/EditarManuales';
import { EditarSoftware } from '../pages/editarsoftware/EditarSoftware';
import { SubirVideoInicial } from '../pages/subirvideoinicial/SubirVideoInicial';
import { EditarVideoInicial } from '../pages/editarvideoinicial/EditarVideoInicial';


export const DashboardRoutes = () => {
    return (
        <div>

             <Navbar/>  
             
             
              <Switch>
                   
              
                  <Route exact path="/" component={Index} />
                  <Route exact path="/subir-video-inicial" component={SubirVideoInicial} />
                  <Route exact path="/editar-videos-iniciales" component={EditarVideoInicial} />
                  <Route exact path="/genericos" component={Genericos} />
                  <Route exact path="/crear-usuario" component={CrearUsuario} />
                  <Route exact path="/editar-usuarios" component={EditarUsuarios} />
                  <Route exact path="/editar-manuales" component={EditarManuales} />
                  <Route exact path="/editar-software" component={EditarSoftware} />
                  <Route exact path="/crear-manual" component={Crearmanual} />
                  <Route exact path="/subir-software" component={Subirsoftware} />
                  <Route exact path="/software" component={Software} />
                  <Route exact path="/sol-y-luna" component={Solyluna} />
                  <Route exact path="/bossanova" component={Bossanova} />
                  <Route exact path="/o2" component={O2} />
                  <Route exact path="/eclipse" component={Eclipse} /> 
                  <Route exact path="/hotel-pance-122" component={Pance122} />
              </Switch>
           </div>   
    )
}
