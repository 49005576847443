import React from 'react';
import '../../../node_modules/video-react/dist/video-react.css';
import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react';
import { TitulosDinamicos } from '../../components/TitulosDinamicos';
import { url_base } from '../../env/env';

export const Videoindex = ({data}) => {

    console.log('aragan ',data)
    
 
     return (
         <>  
             
               {
               
                      data ?
                      <> 
                       <TitulosDinamicos 
                         title={data.title_video}
                         />
                         <Player
                           playsInline
                           poster="/assets/images/poster.png"
                           src={ url_base()+'/'+data.folder_video+'/'+data.src_video || ''}
                         >
                         <ControlBar>
                             <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                         </ControlBar>
                         </Player>
                       </>
                       :
                        <TitulosDinamicos 
                          title={'No hay video para mostrar :('}
                        />
                       
                     
               }
         </>
     )
}
