import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDataIndex } from '../../actions/appActions';
import { Videoindex } from '../../components/videoindex/Videoindex';

import './index.css';

export const Index = () => {

    const dispatch =  useDispatch();
    const [data, setData] = useState([]);


    useEffect(() => {
        getData();
    }, []);

    const getData = async() => {
        const result = await dispatch( getDataIndex() );
        setData(result);
    }
    
    return (
        <>   
            <div className="row own-container m-t-2">
                <div className="col-md-9 col-sm-12 center-auto p-20">
                    <Videoindex data={data}/>
                </div>
                
            </div>
        </>
    )
}
