import React, { useEffect, useState } from 'react';
import '../../../node_modules/video-react/dist/video-react.css';
import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react';
import { TitulosDinamicos } from '../../components/TitulosDinamicos';
import { url_base } from '../../env/env';
import { useSelector } from 'react-redux';


export const Video = ( {data} ) => {

  const { tittle_video, url_video, folder_video } = useSelector( state => state.titleandvideo )

   const [titlevideo, setTittleVideo] = useState('');
   const [urlvideo, setUrlVideo] = useState('');
   const [foldervideo, setFolderVideo] = useState('');


   useEffect(() => {
      setTittleVideo(titlevideo)
      setUrlVideo(urlvideo)
      setFolderVideo(foldervideo)
   }, [tittle_video])
   

    return (
        <>  
            
              {
              
                     data ?
                     <> 
                      <TitulosDinamicos 
                        title={tittle_video}
                        />
                        <Player
                          playsInline
                          poster="/assets/images/poster.png"
                          src={ url_base()+'/'+folder_video+'/'+url_video}
                        >
                        <ControlBar>
                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                        </ControlBar>
                        </Player>
                      </>
                      :
                      <TitulosDinamicos title="No hay contenido para mostrar"/>
                    
              }
        </>
    )
}
