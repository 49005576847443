import React from 'react';
import { url_base } from '../env/env';

export const ListaSoftware = ( { data } ) => {

    
    return (
        <>
            
            <ul className="list-group list-group-horizontal">
               {
                  data.map( (item) => 
                     <li className="list-group-item m-r-7" key={item.id.toString()}>
                        <a download rel="noreferrer" target="_blank" href={url_base()+'/'+item.folder_software+'/'+item.src_software} className="list-group-item list-group-item-action">{item.title_software}</a>
                        <a download rel="noreferrer" target="_blank" href={url_base()+'/'+item.folder_manual+'/'+item.src_manual} className='txt-instruciones-software'>Instruciones</a>
                     </li>
                  ) 
               }
                
            </ul>
        </>
    )
}
