import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { activeManual,  desactiveManual,  getManuales } from '../../actions/appActions';
import { FilterComponent } from '../../components/datatables/FilterComponent';
import { Alerts } from '../../components/messages/Alerts';

export const EditarManuales = () => {
  

const dispatch = useDispatch();

const [loadingdt, setLoadinGdt] = useState(true);
const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
const [filterText, setFilterText] = useState('');
const [data, setData] = useState([]);

useEffect(() => {
    getData();
}, []);



const getData = async() => {
    
    const arr = [];
    const result = await dispatch(  getManuales() );
    
   
    (!result.ok) && Alerts({ 
        icon: 'error',
        title: 'Oops...',
        text: result.msg,
        footer: '<a href="">Qihotesl manuales</a>'
    });

    if(!result.ok){return}
       
   console.log('kekek ',result.data)
    result.data.forEach( element => {
        arr.push({
            id: element.identifier,
            id_table: element.id,
            title_document: element.title_document,
            title_video:element.title_video,
            hotel: element.hotel,
            hotel_manuales: element.hotel_manuales,
            status: element.status
        });
    });
    setData(arr);
    setLoadinGdt(false);
}



const openCustomModal = () => {

}



const desactivarManual = async(id, hotel_manuales) => {
    setLoadinGdt(true);
      const result = await dispatch(  desactiveManual(id,hotel_manuales) );

      (!result.ok) && Alerts({ 
        icon: 'error',
        title: 'Oops...',
        text: result.msg,
        footer: '<a href="">Qihotesl manuales</a>'
      });
      getData();
      if(!result.ok){return}

    getData();
}

const activarManual = async(id, hotel_manuales) => {
     setLoadinGdt(true);
     const result = await dispatch(  activeManual(id, hotel_manuales) );

     (!result.ok) && Alerts({ 
        icon: 'error',
        title: 'Oops...',
        text: result.msg,
        footer: '<a href="">Qihotesl manuales</a>'
      });
      getData();
      if(!result.ok){return}
      getData()
}

 const filteredItems = data.filter(
    item => item.hotel && item.hotel.toLowerCase().includes(filterText.toLowerCase()),
 );

 const columns = [
    {
        name: 'Nombre documento',
        selector: row => row.title_document,
    },
    {
        name: 'Nombre video',
        selector: row => row.title_video,
    },
    {
        name: 'Hotel',
        selector: row => row.hotel,
    },
    {
        name: 'Editar',
        button: true,
        cell: row => (
            
            (row.status == 1) 
                    ?
                      <button  target="_blank" onClick={ () => openCustomModal(row.id_table) } rel="noopener noreferrer" className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">Editar</button>
                    :
                    ''
            
        ),
    },
    {
        name: 'Acciones',
        button: true,
        cell: row => (
                  (row.status == 1) 
                    ?
                    <button   onClick={ () => desactivarManual(row.id_table, row.hotel_manuales)}  rel="noopener noreferrer" className="btn btn-danger btn-sm">Desactivar</button>
                    :
                    <button   onClick={ () => activarManual(row.id_table, row.hotel_manuales)}     rel="noopener noreferrer" className="btn btn-warning btn-sm">Activar</button>           
        ),
    },
 ];

 


 const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };

    return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
}, [filterText, resetPaginationToggle]);


  return (
    <>
      <div className='container m-t-15p'>
        <DataTable
            title="Gestion manuales"
            columns={columns}
            data={filteredItems}
            progressPending={loadingdt}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            selectableRows
            persistTableHead/>


      </div>
    </>
  )


}
